  <template>
  <div class="diy_list page_home_stay_information" id="home_stay_information_list">
    <div class="warp">
      <div class="container diy_list_container">
        <!--        <div class="diy_list_title">-->
        <!--          <div class="col">-->
        <!--            <span class="title">产品信息列表</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="row diy_list_search">-->
        <!--          <div class="col">-->
        <!--            &lt;!&ndash; 搜索栏 &ndash;&gt;-->
        <!--            <div class="view">-->
        <!--              <span class="diy_list_search_title">关键字搜索：</span>-->
        <!--              <b-form-input size="sm" class="mr-sm-2" placeholder="产品名称搜索" v-model="query['name_of_home_stay']"/>-->
        <!--              <b-form-input size="sm" class="mr-sm-2" placeholder="产品类型搜索" v-model="query['room_type']"/>-->
        <!--              <b-button size="sm" @click="search()">-->
        <!--                <b-icon icon="search"/>-->
        <!--              </b-button>-->
        <!--            </div>-->
        <!--            &lt;!&ndash; /搜索栏 &ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="diy_list_select_box">
<!--          <span class="diy_list_select_title">下拉搜索：</span>-->
<!--          <div class="diy_list_dropdown_box">-->
<!--            <div class="col">-->
<!--              &lt;!&ndash; 筛选 &ndash;&gt;-->
<!--              <div class="view">-->
<!--                <b-dropdown text="产品系列" variant="outline-dark" left>-->
<!--                  <b-dropdown-item @click="filter_set('全部','room_type')">全部</b-dropdown-item>-->
<!--                  <b-dropdown-item v-for="(o, i) in list_room_type" :key="i"-->
<!--                                   @click="filter_set(o['room_type'],'room_type')">-->
<!--                    {{ o['room_type'] }}-->
<!--                  </b-dropdown-item>-->
<!--                </b-dropdown>-->
<!--              </div>-->
<!--              &lt;!&ndash; /筛选 &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
          <el-menu :default-active="this.default_index" mode="horizontal" @select="handleSelect">
            <template v-for="(o, i) in list_room_type">
              <el-menu-item :index="o.room_type">{{ o.room_type }}</el-menu-item>
            </template>
          </el-menu>

<!--          <div class="diy_list_sort_box">-->
<!--            <div class="col">-->
<!--              &lt;!&ndash; 排序 &ndash;&gt;-->
<!--              <div class="view">-->
<!--                <b-dropdown text="排序" variant="outline-dark" left>-->
<!--                  <b-dropdown-item v-for="(o, i) in list_sort" :key="i" @click="set_sort(o)">-->
<!--                    {{ o.name }}-->
<!--                  </b-dropdown-item>-->
<!--                </b-dropdown>-->
<!--              </div>-->
<!--              &lt;!&ndash;/排序 &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="row diy_list_box" style="margin-bottom: 5vh">
          <div class="col">
            <!-- 列表 -->
            <list_home_stay_information :list="list"/>
            <!-- /列表 -->
          </div>
        </div>
        <!--        <div class="row diy_list_page_box">-->
        <!--          <div class="col overflow-auto flex_cc">-->
        <!--            &lt;!&ndash; 分页器 &ndash;&gt;-->
        <!--            &lt;!&ndash;						<diy_pager v-model="query['page']" :size="query['size']" :count="count" v-on:toPage="toPage" v-on:toSize="toSize" ></diy_pager>&ndash;&gt;-->
        <!--            <b-pagination-->
        <!--                v-model="query.page"-->
        <!--                :total-rows="count"-->
        <!--                :per-page="query.size"-->
        <!--                @change="goToPage"-->
        <!--            />-->
        <!--            &lt;!&ndash; /分页器 &ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="jmly">
      <router-link to="/consultation_information/edit">
        <div class="jmly_to"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import list_home_stay_information from "@/components/diy/list_home_stay_information.vue";
import diy_pager from "@/components/diy/diy_pager";
import mixin from "@/mixins/page.js";

export default {
  mixins: [mixin],
  components: {
    diy_pager,
    list_home_stay_information
  },
  data() {
    return {
      url_get_list: "~/api/home_stay_information/get_list?like=0",

      // 查询条件
      query: {
        keyword: "",
        page: 1,
        size: 10,
        "name_of_home_stay": "", // 民宿名称
        "room_type": "", // 房间类型
        "merchant_name": "", // 商家名称
      },

      // 排序内容
      // list_sort: [{
      //   name: "创建时间从高到低",
      //   value: "create_time desc",
      // },
      //   {
      //     name: "创建时间从低到高",
      //     value: "create_time asc",
      //   },
      //   {
      //     name: "更新时间从高到低",
      //     value: "update_time desc",
      //   },
      //   {
      //     name: "更新时间从低到高",
      //     value: "update_time asc",
      //   },
      //   {
      //     name: "民宿名称正序",
      //     value: "name_of_home_stay asc",
      //   },
      //   {
      //     name: "民宿名称倒序",
      //     value: "name_of_home_stay desc",
      //   },
      //   {
      //     name: "房间类型正序",
      //     value: "room_type asc",
      //   },
      //   {
      //     name: "房间类型倒序",
      //     value: "room_type desc",
      //   },
      //   {
      //     name: "商家名称正序",
      //     value: "merchant_name asc",
      //   },
      //   {
      //     name: "商家名称倒序",
      //     value: "merchant_name desc",
      //   },
      // ],
      default_index:'"馍"系列',

      // 房间类型列表
      "list_room_type": [""],

    }
  },
  methods: {
    get_list_before(param) {
    },
    /**
     * 筛选选择
     */
    filter_set(o, key) {
      if (o == "全部") {
        this.query[key] = "";
      } else {
        this.query[key] = o;
      }
      this.search();
    },

    /**
     * 排序
     */
    set_sort(o) {
      this.query.orderby = o.value;
      this.search();
    },

    handleSelect(key, keyPath) {
      this.filter_set(key,'room_type')
    },
    /**
     * 获取房间类型列表
     */
    async get_list_room_type() {
      var json = await this.$get("~/api/room_type/get_list?");
      if (json.result) {
        this.list_room_type = json.result.list;
      } else if (json.error) {
        console.log(json.error);
      }
    },

    /**
     * 筛选
     */
    filter_room_type(o) {
      if (o == "全部") {
        this.query["room_type"] = "";
      } else {
        this.query["room_type"] = o;
      }
      this.search();
    },
    /**
     * 重置
     */
    reset() {
      this.query.name_of_home_stay = ""
      this.query.room_type = ""
      this.query.merchant_name = ""
      this.search();
    },

    // 返回条数
    toSize(i) {
      this.query.size = i;
      this.first();
    },

    // 返回页数
    toPage(i) {
      this.query.page = i;
      this.first();
    },

    goToPage(v) {
      this.query.page = v;
      this.goToNew(v)
    },

  },
  computed: {},
  created() {
    /**
     * 获取房间类型列表
     */
    this.get_list_room_type();
  }
}
</script>

<style>
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid #ff4040;
  color: #303133;
}
.el-menu--horizontal>.el-menu-item {
  font-size: larger;
}
.jmly {
  height: 68px !important;
  width: 130px;
  z-index: 11111112;
  top: 20%;
  right: 0px;
  position: fixed;
  margin-top: -34px;
  margin-left: 0px;
}
.jmly_to {
  height: 100%;
  width: 100%;
  background: url("../../assets/images/join/hzzx.png") no-repeat;
  background-size: 100% 100%;
}
.el-menu-item {
  font-weight: bolder;
}
@media (max-width: 1081px) {
  .jmly_to {
    height: 50%;
    width: 50%;
    background: url("../../assets/images/join/hzzx.png") no-repeat;
    background-size: 100% 100%;
  }

  .jmly {
    height: 68px !important;
    width: 130px;
    z-index: 11111112;
    top: 20%;
    right: -45px;
    position: fixed;
    margin-top: -34px;
    margin-left: 0px;
  }
  #app .diy_list .diy_list_container {
    background: #fff;
    box-sizing: border-box;
    padding: 30px 15px;
    margin-bottom: 0px;
    margin-top: -35px;
  }
  .el-menu--horizontal>.el-menu-item {
    font-size: medium;
  }
}
</style>
